import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import Api from "../Api";
import { match } from "path-to-regexp";
import { LanguageContext } from "../contexts/LanguageContext";
import Loading from "../components/layouts/Loading";

// Cria um contexto para as rotas
const RoutesContext = createContext();

/**
 * Componente RoutesProvider para gerenciar rotas da aplicação.
 *
 * Este provedor é responsável por buscar e fornecer informações sobre as rotas
 * da aplicação, incluindo funções para obter rotas específicas e verificar 
 * se uma rota requer autenticação.
 *
 * @param {Object} props - As propriedades do componente.
 * @param {React.ReactNode} props.children - Os componentes filhos a serem renderizados dentro do provedor.
 *
 * @returns {React.ReactElement} O componente RoutesProvider.
 *
 * @example
 * // Exemplo de uso:
 * <RoutesProvider>
 *   <SeuComponente />
 * </RoutesProvider>
 */
const RoutesProvider = ({ children }) => {
  const { language, txt } = useContext(LanguageContext); // Obtém o contexto de linguagem
  const [routes, setRoutes] = useState([]); // Estado das rotas
  const [loading, setLoading] = useState(true); // Estado de carregamento

  useEffect(() => {
    /**
     * Função assíncrona para buscar as rotas da API.
     */
    const fetchRoutes = async () => {
      try {
        const response = await Api.get(`/routes`);
        setRoutes(response.data); // Atualiza o estado com as rotas recebidas
      } catch (error) {
        // Manipulação de erros pode ser adicionada aqui
      } finally {
        setLoading(false); // Define o estado de carregamento como falso após a busca
      }
    };

    setLoading(true); // Inicia o estado de carregamento
    fetchRoutes(); // Chama a função para buscar as rotas
  }, [language]); // Executa quando a linguagem muda

  /**
   * Obtém uma rota com base no alias fornecido.
   * 
   * @param {string} alias - O alias da rota a ser obtida.
   * @returns {Object|undefined} A rota correspondente ou undefined se não for encontrada.
   */
  const getRoute = useMemo(
    () => (alias) => {
      return routes.find((route) => route.alias === alias);
    },
    [routes]
  );

  /**
   * Verifica se a rota atual requer que o usuário esteja autenticado.
   * 
   * @returns {boolean} True se a rota requer autenticação, caso contrário false.
   */
  const isOnlyLoggedRoute = useMemo(() => {
    const currentPath = window.location.pathname; // Obtém o caminho atual
    for (const route of routes) {
      const matcher = match(route.path, { decode: decodeURIComponent }); // Cria um matcher para a rota
      const matched = matcher(currentPath); // Verifica se o caminho atual corresponde à rota
      if (matched) {
        return route.only_logged ?? false; // Retorna o status de autenticação
      }
    }

    return false; // Retorna false se nenhuma rota corresponder
  }, [routes]);

  /**
   * Obtém todas as rotas disponíveis.
   * 
   * @returns {Array} Um array com todas as rotas.
   */
  const getRoutes = useMemo(
    () => () => {
      return routes;
    },
    [routes]
  );

  /**
   * Obtém o caminho de uma rota com base no alias e parâmetros opcionais.
   * 
   * @param {string} alias - O alias da rota a ser obtida.
   * @param {Object} uuids - Um objeto contendo os parâmetros para substituir no caminho da rota.
   * @returns {string} O caminho formatado da rota ou o alias se não encontrado.
   */
  const getPathRoute = useMemo(
    () =>
      (alias, uuids = {}) => {
        const route = routes.find((route) => route.alias === alias);
        if (route) {
          let path = route.path; // Obtém o caminho da rota
          Object.keys(uuids).forEach((key) => {
            path = path.replace(`:${key}`, uuids[key]); // Substitui os parâmetros no caminho
          });
          return txt(path); // Retorna o caminho traduzido
        }
        return alias ?? "#"; // Retorna o alias ou '#' se não encontrado
      },
    [routes, txt]
  );

  return (
    <RoutesContext.Provider
      value={{
        getRoutes,
        getRoute,
        getPathRoute,
        routes,
        loading,
        isOnlyLoggedRoute,
      }}
    >
      {loading === true ? (
        <Loading /> // Exibe um componente de carregamento enquanto busca as rotas
      ) : (
        children // Renderiza os filhos após as rotas serem carregadas
      )}
    </RoutesContext.Provider>
  );
};

export { RoutesContext, RoutesProvider };
